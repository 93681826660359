::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 20px;
  border: 3px solid #fff;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fff #f0f2f5;
}