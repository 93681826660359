* {
	font-family: 'Tahoma', sans-serif !important;
}
.title,
p {
	color: $dark-grey-transp;
}

legend {
	font-size: 1rem;
}

.spinner-border {
	color: $blue !important;
}

//Cores dos toast

/* Estilo para mensagens de sucesso */
.toast-success {
	background-color: $blue !important; /* Verde escuro */
	color: #ffffff !important; /* Texto branco */
}

/* Estilo para mensagens de erro */
.toast-error {
	background-color: $roxo !important; /* Vermelho escuro */
	color: #ffffff !important; /* Texto branco */
}

/* Estilo para mensagens informativas */
.toast-info {
	background-color: $marinho !important; /* Azul claro */
	color: #ffffff !important; /* Texto branco */
}

/* Estilo para mensagens de aviso */
.toast-warning {
	background-color: $verde-citrico !important; /* Amarelo */
	color: #212529 !important; /* Texto preto */
}

//Cores do toltip
/* Fundo e texto do tooltip */
.tooltip .tooltip-inner {
  background-color: $marinho !important; /* Fundo verde */
  color: #ffffff !important;          /* Texto branco */
}

/* Alterando a cor da seta */
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: $marinho !important; /* Seta quando o tooltip está acima */
}

.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $marinho !important; /* Seta quando o tooltip está abaixo */
}

.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: $marinho !important; /* Seta quando o tooltip está à direita */
}

.tooltip.bs-tooltip-left .arrow::before {
  border-left-color: $marinho !important; /* Seta quando o tooltip está à esquerda */
}

@media screen and (max-width: 1400px) {
  .innerHTML {
    font-size: 1.2rem;
  }
  .text-primary {
    font-size: 1.5rem;
  }

  * {
    font-size: 0.9rem;
  }
  strong {
    font-size: 1rem;
  }
  h4 {
    font-size: 1.2rem;
  }

}
