.card {
	border: none;
}

.card-header-customer,
.card-footer-customer {
	background-color: white !important;
}

.card-header-sub-customer {
	background-color: $off-white-transp-1;
}
