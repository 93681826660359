em {
  margin: none;
  padding: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0;
}

.btn-customer-info {
  color: $roxo !important;
  border: none;
  background-color: #fff;
}

.btn-modal-customer-info {
  color: $blue !important;
  background-color: transparent;
  border: 1px solid #c0c3c0;
  border-radius: 5px;
}

.btn-customer-info:hover,
.btn-modal-customer-info:hover {
  color: #fff !important;
  background: $roxo !important;
  border: 1px solid $roxo;
}

.btn-customer-danger {
  color: $verde-citrico !important;
  background-color: #fff;
  border: none;
}

.btn-modal-customer-danger {
  color: $verde-citrico !important;
  border: 1px solid #c0c3c0;
  background-color: transparent;
  border-radius: 5px;
}

.btn-customer-danger:hover,
.btn-modal-customer-danger:hover {
  color: #fff !important;
  background: $verde-citrico !important;
  border: 1px solid $verde-citrico;
}

.btn-input-customer {
  border: 1px solid #c0c3c0;
  width: 2.5rem;
  height: 2.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  background-color: $off-white-transp;
  color: $roxo !important;
  padding: 0;
}
.btn-input-customer:hover {
  color: #fff !important;
  background: $roxo !important;
  border: 1px solid $roxo;
}
.btn-input-customer,
span {
  margin: 0;
  padding: 0;
}

.btn-customer-create,
.btn-customer-export,
.btn-customer-edit {
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #c0c3c0 !important;
  background: transparent !important;
}

.btn-log-customer {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  margin: 5px;
  border: 1px solid #c0c3c0 !important;
}

.btn:disabled,
.btn-modal-customer-info:disabled {
  background: none !important;
  color: #c0c3c0 !important;
}
