$blue: #1DB4FF;
$blue-transp: #1db4ff6e;
$off-white: #D8E5D3;
$off-white-transp:  #d8e5d394;
$off-white-transp-1: #d8e5d341;
$roxo: #7B18DD;
$marinho: #26539C;
$dark-grey: #100F2C;
$dark-grey-transp: #100f2cc4;
$verde-citrico: #CBDB2A;
$gradiente-darkgray-blue: linear-gradient(180deg, rgba(216,229,211,1) 17%, rgba(156,204,218,1) 75%, rgba(89,176,226,1) 98%);

